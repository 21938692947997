/* eslint-disable max-len */
import React from "react";
import Colors from "../../config/Colors";

export function SuccessIcon({
  height = 1, // number type by default
  width = 1,
  viewBox = "0 0 14 14",
  svgColor = Colors.appMainColor,
}: CustomSVGProp) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height={height}
      width={width}
      viewBox={viewBox || "0 0 14 14"}
      fill="none"
    >
      <g
        fill="none"
        stroke={svgColor}
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <path d="m4 8l2.05 1.64a.48.48 0 0 0 .4.1a.5.5 0 0 0 .34-.24L10 4" />
        <circle cx="7" cy="7" r="6.5" />
      </g>
    </svg>
  );
}
export default SuccessIcon;
