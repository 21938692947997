/* eslint-disable max-len */
import React from "react";
import Colors from "../../config/Colors";

export function MessageIcon({
  height = 1, // number type by default
  width = 1,
  viewBox = "0 0 325 241",
}: CustomSVGProp) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height={height}
      width={width}
      viewBox={viewBox || "0 0 325 241"}
      fill="none"
    >
      <path
        d="M172.842 240.818C256.876 240.818 325 201.486 325 152.967C325 104.449 256.876 65.1167 172.842 65.1167C88.8068 65.1167 20.6831 104.449 20.6831 152.967C20.6831 201.486 88.8068 240.818 172.842 240.818Z"
        fill={Colors.subtleBlue}
      />
      <path
        d="M118.705 124.17L209.934 71.5034L213.587 73.6101C214.085 73.9377 214.499 74.3776 214.796 74.8943C215.093 75.4111 215.264 75.9903 215.297 76.5854V143.882C215.265 144.478 215.093 145.057 214.796 145.574C214.499 146.091 214.085 146.53 213.587 146.858L125.784 197.541C125.253 197.805 124.667 197.943 124.074 197.943C123.481 197.943 122.895 197.805 122.364 197.541L120.435 196.426C119.937 196.098 119.522 195.658 119.224 195.142C118.926 194.625 118.753 194.046 118.719 193.45L118.705 124.17Z"
        fill={Colors.appMainColor}
      />
      <path
        opacity="0.05"
        d="M118.705 124.17L209.934 71.5034L213.587 73.6101C214.085 73.9377 214.499 74.3776 214.796 74.8943C215.093 75.4111 215.264 75.9903 215.297 76.5854V143.882C215.265 144.478 215.093 145.057 214.796 145.574C214.499 146.091 214.085 146.53 213.587 146.858L125.784 197.541C125.253 197.805 124.667 197.943 124.074 197.943C123.481 197.943 122.895 197.805 122.364 197.541L120.435 196.426C119.937 196.098 119.522 195.658 119.224 195.142C118.926 194.625 118.753 194.046 118.719 193.45L118.705 124.17Z"
        fill="black"
      />
      <path
        d="M209.428 65.5803L209.804 65.3752L208.388 64.5544C208.266 64.4853 208.137 64.4259 208.005 64.3765L167.856 49.5C167.305 49.3153 166.71 49.3106 166.157 49.4864C165.604 49.6622 165.121 50.0095 164.778 50.478L119.861 116.065C119.163 117.196 118.766 118.486 118.705 119.813V124.17L120.073 124.977L209.914 71.4968V67.1398C209.901 66.5848 209.733 66.0445 209.428 65.5803Z"
        fill={Colors.appMainColor}
      />
      <path
        opacity="0.1"
        d="M166.631 50.7993C166.453 50.9393 166.296 51.1053 166.166 51.2918L121.249 116.879C120.88 117.459 120.599 118.09 120.415 118.753L119.047 117.946C119.148 117.608 119.269 117.277 119.409 116.954C119.471 116.817 119.532 116.687 119.601 116.557V116.496C119.672 116.358 119.752 116.226 119.84 116.099L164.771 50.4847C164.839 50.3875 164.914 50.296 164.997 50.2111L165.236 49.9922L166.631 50.7993Z"
        fill="black"
      />
      <path
        opacity="0.35"
        d="M209.784 65.3751C209.662 65.3012 209.534 65.2394 209.401 65.1904L169.244 50.307C168.803 50.1601 168.331 50.1283 167.874 50.2145C167.417 50.3006 166.989 50.502 166.631 50.7994L165.263 49.9923C165.624 49.6977 166.053 49.4982 166.51 49.4122C166.968 49.3261 167.44 49.3563 167.883 49.4999L208.033 64.3765C208.164 64.4255 208.29 64.4873 208.409 64.5611L209.784 65.3751Z"
        fill="white"
      />
      <path
        d="M165.003 50.2117C165.077 50.1316 165.157 50.0581 165.244 49.9922C165.156 50.0563 165.075 50.1299 165.003 50.2117Z"
        fill={Colors.appMainColor}
      />
      <path
        opacity="0.2"
        d="M119.587 116.53C119.587 116.53 119.587 116.482 119.587 116.462C119.587 116.441 119.601 116.51 119.587 116.53Z"
        fill="black"
      />
      <path
        opacity="0.2"
        d="M164.778 50.479C164.844 50.3821 164.92 50.2924 165.004 50.2114C164.918 50.2906 164.842 50.3805 164.778 50.479Z"
        fill="black"
      />
      <path
        d="M166.166 51.2988L121.256 116.893C120.553 118.019 120.152 119.307 120.093 120.634V124.991L211.323 72.3243V67.9469C211.289 67.3571 211.091 66.7886 210.751 66.3053C210.412 65.8219 209.944 65.4429 209.401 65.2109L169.251 50.3343C168.701 50.152 168.107 50.147 167.553 50.32C167 50.4931 166.515 50.8354 166.166 51.2988Z"
        fill={Colors.appMainColor}
      />
      <path
        d="M130.025 188.375L127.097 186.686C126.856 186.502 126.666 186.258 126.546 185.979C126.426 185.7 126.381 185.394 126.413 185.092V93.8765C126.452 92.9505 126.735 92.0513 127.234 91.2705L130.209 92.9805C129.706 93.7644 129.423 94.6691 129.389 95.6001V186.782C129.352 187.079 129.392 187.381 129.503 187.66C129.614 187.938 129.793 188.185 130.025 188.375Z"
        fill="#E0E0E0"
      />
      <path
        d="M131.851 91.2433L205.133 48.9459C206.501 48.1388 207.643 48.7886 207.643 50.396V141.584C207.597 142.457 207.346 143.306 206.91 144.064C206.474 144.821 205.865 145.464 205.133 145.941L131.851 188.232C130.483 189.032 129.341 188.382 129.341 186.775V95.6003C129.388 94.728 129.64 93.8792 130.076 93.1223C130.512 92.3654 131.12 91.7217 131.851 91.2433Z"
        fill="#F0F0F0"
      />
      <path
        d="M203.936 47.0513L206.809 48.7202C206.53 48.6165 206.231 48.5841 205.937 48.6259C205.643 48.6677 205.364 48.7824 205.126 48.9596L131.851 91.2502C131.148 91.6739 130.564 92.2694 130.155 92.9806L127.179 91.2707C127.592 90.5605 128.172 89.9618 128.869 89.5265L202.151 47.1949C202.405 47.0076 202.706 46.8938 203.021 46.866C203.336 46.8383 203.652 46.8976 203.936 47.0376V47.0513Z"
        fill="#FAFAFA"
      />
      <path
        d="M137.569 96.0174L198.505 60.8334C199.326 60.3615 199.989 60.6966 199.989 61.5858C199.952 62.0829 199.798 62.5642 199.541 62.9908C199.283 63.4174 198.928 63.7773 198.505 64.0413L137.569 99.2253C136.748 99.6972 136.085 99.3621 136.085 98.4797C136.121 97.9814 136.274 97.4987 136.532 97.0709C136.79 96.643 137.145 96.282 137.569 96.0174Z"
        fill="#E0E0E0"
      />
      <path
        d="M137.569 102.412L198.505 67.2284C199.326 66.7565 199.989 67.0916 199.989 67.9808C199.952 68.4779 199.798 68.9593 199.541 69.3859C199.283 69.8125 198.928 70.1723 198.505 70.4363L137.569 105.62C136.748 106.092 136.085 105.757 136.085 104.875C136.121 104.376 136.274 103.894 136.532 103.466C136.79 103.038 137.145 102.677 137.569 102.412Z"
        fill="#E0E0E0"
      />
      <path
        d="M137.569 108.808L198.505 73.6241C199.326 73.1522 199.989 73.4873 199.989 74.3697C199.953 74.868 199.8 75.3507 199.542 75.7786C199.284 76.2064 198.929 76.5674 198.505 76.832L137.569 112.016C136.748 112.488 136.085 112.153 136.085 111.27C136.121 110.772 136.274 110.289 136.532 109.862C136.79 109.434 137.145 109.073 137.569 108.808Z"
        fill="#E0E0E0"
      />
      <path
        d="M137.569 115.203L198.505 80.026C199.326 79.554 199.989 79.8892 199.989 80.7715C199.953 81.2698 199.8 81.7525 199.542 82.1804C199.284 82.6083 198.929 82.9692 198.505 83.2339L137.569 118.418C136.748 118.89 136.085 118.555 136.085 117.665C136.121 117.167 136.274 116.684 136.532 116.257C136.79 115.829 137.145 115.468 137.569 115.203Z"
        fill="#E0E0E0"
      />
      <path
        d="M137.569 121.598L198.505 86.4142C199.326 85.9422 199.989 86.2774 199.989 87.1597C199.953 87.658 199.8 88.1407 199.542 88.5686C199.284 88.9965 198.929 89.3574 198.505 89.622L137.569 124.806C136.748 125.278 136.085 124.943 136.085 124.054C136.122 123.557 136.276 123.075 136.534 122.649C136.792 122.222 137.146 121.862 137.569 121.598Z"
        fill="#E0E0E0"
      />
      <path
        d="M137.569 127.994L198.505 92.8097C199.326 92.3377 199.989 92.6729 199.989 93.5552C199.953 94.0535 199.8 94.5362 199.542 94.9641C199.284 95.392 198.929 95.7529 198.505 96.0176L137.569 131.202C136.748 131.674 136.085 131.338 136.085 130.449C136.122 129.952 136.276 129.471 136.534 129.044C136.792 128.618 137.146 128.258 137.569 127.994Z"
        fill="#E0E0E0"
      />
      <path
        d="M137.569 134.389L198.505 99.1774C199.326 98.7054 199.989 99.0406 199.989 99.9229C199.953 100.421 199.8 100.904 199.542 101.332C199.284 101.76 198.929 102.121 198.505 102.385L137.569 137.569C136.748 138.041 136.085 137.706 136.085 136.817C136.126 136.325 136.282 135.849 136.54 135.427C136.797 135.006 137.15 134.65 137.569 134.389Z"
        fill="#E0E0E0"
      />
      <path
        d="M137.569 140.784L198.505 105.6C199.326 105.121 199.989 105.463 199.989 106.345C199.953 106.844 199.8 107.326 199.542 107.754C199.284 108.182 198.929 108.543 198.505 108.808L137.569 143.992C136.748 144.464 136.085 144.128 136.085 143.239C136.122 142.742 136.276 142.261 136.534 141.834C136.792 141.408 137.146 141.048 137.569 140.784Z"
        fill="#E0E0E0"
      />
      <path
        d="M212.985 144.772C212.958 144.82 212.926 144.866 212.889 144.909L174.873 128.048C173.43 127.41 171.805 127.314 170.296 127.779C168.788 128.244 167.499 129.239 166.666 130.579L126.023 195.79L124.519 196.659C124.351 196.776 124.157 196.849 123.954 196.871C123.751 196.894 123.545 196.866 123.356 196.788L165.154 129.717C165.989 128.379 167.278 127.386 168.785 126.92C170.293 126.454 171.917 126.546 173.362 127.18L212.985 144.772Z"
        fill={Colors.appMainColor}
      />
      <path
        opacity="0.4"
        d="M162.911 136.509L163.006 136.427L126.01 195.797L124.505 196.665C124.337 196.781 124.143 196.853 123.94 196.876C123.737 196.898 123.532 196.871 123.342 196.795L161.42 135.634L162.911 136.509Z"
        fill="white"
      />
      <path
        opacity="0.4"
        d="M212.985 144.779C212.958 144.827 212.926 144.873 212.889 144.915L174.867 128.048C173.423 127.41 171.798 127.314 170.29 127.779C168.782 128.244 167.492 129.239 166.659 130.579L165.154 129.704C165.993 128.369 167.283 127.38 168.79 126.918C170.297 126.456 171.92 126.551 173.362 127.187L212.985 144.779Z"
        fill="white"
      />
      <path
        d="M215.269 76.1477C215.187 75.6552 214.927 75.5184 214.585 75.751C214.416 75.8771 214.269 76.0295 214.148 76.2024L213.956 76.4692L176.467 128.698L170.865 136.502C170.505 136.957 170.027 137.305 169.483 137.508C168.94 137.712 168.351 137.764 167.781 137.658L162.945 136.509L126.01 127.727C125.762 127.676 125.506 127.676 125.257 127.727C125.161 127.746 125.067 127.776 124.977 127.816L125.996 127.227L161.563 135.688L166.413 136.796C166.983 136.904 167.573 136.853 168.116 136.649C168.66 136.445 169.138 136.096 169.497 135.64L175.099 127.843L212.8 75.3474C212.915 75.1914 213.059 75.059 213.224 74.9576L209.982 73.0903L211.35 72.3037L213.607 73.6033C214.04 73.8951 214.41 74.2705 214.696 74.7075C214.981 75.1445 215.176 75.6342 215.269 76.1477Z"
        fill={Colors.appMainColor}
      />
      <path
        opacity="0.4"
        d="M176.467 128.698L170.865 136.502C170.505 136.957 170.027 137.305 169.483 137.509C168.94 137.712 168.351 137.764 167.781 137.658L162.945 136.509L163.041 136.427L163.273 136.058L166.44 136.81C167.011 136.918 167.6 136.867 168.144 136.663C168.687 136.459 169.165 136.11 169.525 135.654L175.072 127.939L176.467 128.698Z"
        fill="white"
      />
      <path
        opacity="0.1"
        d="M213.58 73.6033L211.323 72.3037L209.955 73.0903L213.217 74.9712C213.053 75.0732 212.909 75.2055 212.793 75.3611L175.099 127.857L175.044 127.932L176.412 128.691L213.949 76.4692L214.141 76.2024C214.262 76.0295 214.409 75.8771 214.579 75.751C214.914 75.5184 215.174 75.6552 215.263 76.1477C215.167 75.6325 214.968 75.1418 214.679 74.7047C214.39 74.2677 214.017 73.8931 213.58 73.6033Z"
        fill="black"
      />
      <path
        d="M126.748 128.821L118.705 124.17V193.45C118.739 194.046 118.912 194.625 119.21 195.142C119.508 195.658 119.923 196.098 120.422 196.426L122.35 197.541C122.882 197.805 123.467 197.943 124.06 197.943C124.654 197.943 125.239 197.805 125.77 197.541L126.735 196.98L126.748 128.821Z"
        fill={Colors.appMainColor}
      />
      <path
        opacity="0.15"
        d="M126.748 128.821L118.705 124.17V193.45C118.739 194.046 118.912 194.625 119.21 195.142C119.508 195.658 119.923 196.098 120.422 196.426L122.35 197.541C122.882 197.805 123.467 197.943 124.06 197.943C124.654 197.943 125.239 197.805 125.77 197.541L126.735 196.98L126.748 128.821Z"
        fill="black"
      />
      <path
        d="M213.956 76.4692V143.034C213.938 143.651 213.754 144.251 213.423 144.772C213.172 145.213 212.829 145.596 212.417 145.893L212.356 145.934L212.239 146.01L124.444 196.693C124.338 196.758 124.226 196.81 124.108 196.85C124.101 196.747 124.101 196.645 124.108 196.542V129.273C124.093 129.032 124.137 128.791 124.235 128.57C124.334 128.35 124.485 128.156 124.674 128.007C124.864 127.858 125.087 127.757 125.325 127.713C125.562 127.669 125.807 127.683 126.037 127.754L162.972 136.536L167.808 137.686C168.379 137.791 168.967 137.74 169.511 137.536C170.054 137.332 170.532 136.984 170.893 136.53L176.467 128.698L213.956 76.4692Z"
        fill="#455A64"
      />
      <path
        d="M215.304 76.606V143.903C215.282 144.519 215.098 145.118 214.77 145.64C214.515 146.081 214.173 146.465 213.765 146.769L213.696 146.803C213.661 146.83 213.625 146.856 213.587 146.878L125.791 197.534C125.623 197.653 125.428 197.728 125.224 197.752C125.019 197.776 124.812 197.748 124.621 197.671C124.476 197.586 124.353 197.467 124.264 197.324C124.174 197.181 124.121 197.018 124.108 196.85C124.101 196.747 124.101 196.645 124.108 196.542V129.273C124.093 129.032 124.137 128.791 124.235 128.57C124.334 128.35 124.485 128.156 124.674 128.007C124.864 127.858 125.087 127.757 125.325 127.713C125.562 127.669 125.807 127.683 126.037 127.754L162.972 136.536L167.808 137.686C168.379 137.791 168.967 137.74 169.511 137.536C170.054 137.332 170.532 136.984 170.893 136.53L176.467 128.698L213.984 76.4692L214.175 76.2025C214.77 75.3202 215.304 75.4843 215.304 76.606Z"
        fill={Colors.appMainColor}
      />
      <path
        d="M213.587 146.851L213.696 146.776L213.765 146.742C214.173 146.438 214.515 146.054 214.77 145.613C215.089 145.1 215.271 144.514 215.297 143.91L176.467 128.698L202.458 153.274L213.587 146.851Z"
        fill={Colors.appMainColor}
      />
      <path
        opacity="0.2"
        d="M213.587 146.851L213.696 146.776L213.765 146.742C214.173 146.438 214.515 146.054 214.77 145.613C215.089 145.1 215.271 144.514 215.297 143.91L176.467 128.698L202.458 153.274L213.587 146.851Z"
        fill="black"
      />
      <path
        d="M124.074 196.549C124.049 196.768 124.086 196.99 124.18 197.19C124.274 197.389 124.423 197.558 124.608 197.678C124.799 197.755 125.006 197.783 125.21 197.759C125.415 197.735 125.61 197.66 125.777 197.541L156.003 180.093L162.904 136.516L124.074 195.427V196.549Z"
        fill={Colors.appMainColor}
      />
      <path
        opacity="0.15"
        d="M166.638 130.572L124.601 197.67C124.415 197.551 124.267 197.382 124.173 197.182C124.079 196.983 124.042 196.761 124.067 196.542V195.427L161.16 136.112L161.447 135.654L165.133 129.744L166.638 130.572Z"
        fill="black"
      />
      <path
        d="M214.77 145.613C214.515 146.053 214.173 146.437 213.765 146.741L213.696 146.775L125.791 197.534C125.623 197.653 125.428 197.728 125.224 197.752C125.019 197.776 124.812 197.748 124.621 197.671L166.659 130.551C167.502 129.205 168.802 128.207 170.321 127.741C171.839 127.275 173.475 127.372 174.928 128.014L214.77 145.613Z"
        fill={Colors.appMainColor}
      />
      <path
        d="M214.77 145.62C214.519 146.061 214.176 146.443 213.765 146.741L213.703 146.782L125.784 197.541C125.615 197.658 125.42 197.731 125.216 197.753C125.012 197.776 124.805 197.748 124.615 197.671L125.784 195.81L212.178 145.921L212.246 145.879C212.61 145.613 212.919 145.278 213.156 144.895L214.77 145.62Z"
        fill={Colors.appMainColor}
      />
      <path
        opacity="0.15"
        d="M119.013 117.946L120.381 118.753C120.187 119.36 120.079 119.99 120.059 120.627V124.977L118.691 124.17V119.813C118.711 119.179 118.819 118.55 119.013 117.946Z"
        fill="black"
      />
      <path
        d="M127.275 127.508L125.989 127.2L124.97 127.789L123.561 126.975L120.093 124.978L121.441 124.177L127.275 127.508Z"
        fill={Colors.appMainColor}
      />
      <path
        opacity="0.1"
        d="M127.275 127.508L121.448 124.184L120.08 124.984L123.547 126.981L124.956 127.795C125.038 127.754 125.126 127.727 125.216 127.713C125.465 127.662 125.72 127.662 125.969 127.713L128.807 128.397L161.16 136.112L161.447 135.654L127.275 127.508Z"
        fill="black"
      />
    </svg>
  );
}
export default MessageIcon;
