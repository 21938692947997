/* eslint-disable max-len */
/* eslint-disable prettier/prettier */
import React from "react";
import Colors from "../../config/Colors";

export function CompaniesIcon({
  height,
  width,
  viewBox = "0 0 24 24",
  svgColor = `${Colors.secondaryColor}`,
}: CustomSVGProp) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox={viewBox || "0 0 24 24"}
    >
      <path
        fill={svgColor || "#000000"}
        d="M5 3v18h6v-3.5h2V21h6V3H5m2 2h2v2H7V5m4 0h2v2h-2V5m4 0h2v2h-2V5M7 9h2v2H7V9m4 0h2v2h-2V9m4 0h2v2h-2V9m-8 4h2v2H7v-2m4 0h2v2h-2v-2m4 0h2v2h-2v-2m-8 4h2v2H7v-2m8 0h2v2h-2v-2Z"
      />
    </svg>
  );
}

export default CompaniesIcon;
