const Colors = {
  // Main app
  appMainColor: "#1757A2",
  secondaryColor: "#99C7EB",
  subtleBlue: "#F4F9FD",
  // Content
  backgroundColor: "#FBFBFB",
  popupColor: "#FFFFFF",
  // Text
  mainTextColor: "#515151",
  subtleTextColor: "#9B9B9B",
  appHeaderColor: "#1757A2",
  // Status
  successColor: "#179717",
  backgroundSuccessColor: "#D1FADF",
  warningColor: "#FDB022",
  backgroundWarningColor: "#FEF0C7",
  errorColor: "#D92D20",
  backgroundErrorColor: "#FDA29B",
  // Table
  headerTextColor: "#212121",
  bodyTextColor: "#515151",
  tableLinesColor: "#E6E6E6",
};

export default Colors;
