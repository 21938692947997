import { createQueryKeys } from "@lukemorales/query-key-factory";
import ControlRoomOperator from "../ControlRoomOperator";

export const controlRoomOperators = createQueryKeys("controlRoomOperators", {
  all: null,
  getControlRoomOperators: (pageNumber: number, pageSize: number) => ({
    queryKey: [{ pageNumber, pageSize }],
    queryFn: () =>
      ControlRoomOperator.getControlRoomOperators(pageNumber, pageSize),
  }),
  getControlRoomOperatorShifts: (
    controlRoomOperatorId: number,
    pageNumber: number,
    pageSize: number
  ) => ({
    queryKey: [{ controlRoomOperatorId, pageNumber, pageSize }],
    queryFn: () =>
      ControlRoomOperator.getControlRoomOperatorShifts(
        controlRoomOperatorId,
        pageNumber,
        pageSize
      ),
  }),
});
