/* eslint-disable max-len */
import React from "react";
import Colors from "../../config/Colors";

export function Ambulance({
  height, // number type by default
  width,
  viewBox = "0 0 24 24",
  svgColor = `${Colors.appMainColor}`,
}: CustomSVGProp) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height={height}
      width={width}
      viewBox={viewBox || "0 0 24 24"}
      fill="none"
    >
      <g stroke={svgColor}>
        <g strokeLinecap="square" strokeMiterlimit="10" strokeWidth="2">
          <path d="m7 23c1.65685 0 3-1.3431 3-3s-1.34315-3-3-3-3 1.3431-3 3 1.34315 3 3 3z" />
          <path d="m17 23c1.6569 0 3-1.3431 3-3s-1.3431-3-3-3-3 1.3431-3 3 1.3431 3 3 3z" />
          <path d="m14 20h-4" />
          <path d="m4 20h-3v-15h17l5 6v9h-3" />
        </g>
        <path
          d="m9.5 2.5v-.5c0-.39782.15804-.77936.43934-1.06066.28126-.281305.66286-.43934 1.06066-.43934s.7794.158035 1.0607.43934c.2813.2813.4393.66284.4393 1.06066v.5z"
          fill={svgColor}
        />
        <path
          d="m16 9v3h4"
          strokeLinecap="square"
          strokeMiterlimit="10"
          strokeWidth="2"
        />
        <path
          d="m9 9v4"
          strokeLinecap="square"
          strokeMiterlimit="10"
          strokeWidth="2"
        />
        <path
          d="m7 11h4"
          strokeLinecap="square"
          strokeMiterlimit="10"
          strokeWidth="2"
        />
      </g>
    </svg>
  );
}
export default Ambulance;
