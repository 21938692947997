/* eslint-disable  react/self-closing-comp */
import React from "react";

export function MoreVertical({
  height, // number type by default
  width,
  viewBox = "0 0 24 24",
  svgColor = "#888888",
}: CustomSVGProp) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height={height}
      width={width}
      viewBox={viewBox || "0 0 24 24"}
      fill="none"
    >
      <g fill="none" stroke={svgColor} strokeWidth="2">
        <circle cx="12" cy="4" r="1" transform="rotate(90 12 4)"></circle>
        <circle cx="12" cy="12" r="1" transform="rotate(90 12 12)"></circle>
        <circle cx="12" cy="20" r="1" transform="rotate(90 12 20)"></circle>
      </g>
    </svg>
  );
}
export default MoreVertical;
