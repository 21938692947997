/* eslint-disable max-len */
import React from "react";
import Colors from "../../config/Colors";

export function UsersIcon({
  height, // number type by default
  width,
  viewBox = "0 0 256 256",
  svgColor = `${Colors.appMainColor}`,
}: CustomSVGProp) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height={height}
      width={width}
      viewBox={viewBox || "0 0 256 256"}
      fill="none"
    >
      <path
        fill={svgColor || "#888888"}
        d="M117.25 157.92a60 60 0 1 0-66.5 0a95.83 95.83 0 0 0-47.22 37.71a8 8 0 1 0 13.4 8.74a80 80 0 0 1 134.14 0a8 8 0 0 0 13.4-8.74a95.83 95.83 0 0 0-47.22-37.71ZM40 108a44 44 0 1 1 44 44a44.05 44.05 0 0 1-44-44Zm210.14 98.7a8 8 0 0 1-11.07-2.33A79.83 79.83 0 0 0 172 168a8 8 0 0 1 0-16a44 44 0 1 0-16.34-84.87a8 8 0 1 1-5.94-14.85a60 60 0 0 1 55.53 105.64a95.83 95.83 0 0 1 47.22 37.71a8 8 0 0 1-2.33 11.07Z"
      />
    </svg>
  );
}
export default UsersIcon;
