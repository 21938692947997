/* eslint-disable max-len */
import React from "react";
import Colors from "../../config/Colors";

export function ArrowUpward({
  height, // number type by default
  width,
  viewBox = "0 0 24 24",
  svgColor = `${Colors.appMainColor}`,
}: CustomSVGProp) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height={height}
      width={width}
      viewBox={viewBox || "0 0 24 24"}
      fill="none"
    >
      <path
        fill={svgColor || "#888888"}
        d="M11 20V7.825l-5.6 5.6L4 12l8-8l8 8l-1.4 1.425l-5.6-5.6V20h-2Z"
      />
    </svg>
  );
}
export default ArrowUpward;
