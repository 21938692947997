import React, { useState } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import moment from "moment";
import { Tag } from "antd";

import Spin from "@/components/widgets/Spinner/Spin";
import { useGetControlRoomOperatorShifts } from "@/hooks/useControlRoomOperators";

import { tableBody_2, TableHeader_2 } from "@/styles/TableStyles"; // Adjust the path as necessary

type ShiftTableProps = {
  controlRoomOperatorId: number;
};

type ShiftStatusColor = {
  bgColor: string;
  textColor: string;
};

const ControlRoomOperatorShiftsTable: React.FC<ShiftTableProps> = ({
  controlRoomOperatorId,
}) => {
  const [page, setPage] = useState(1);
  const [rows, setRows] = useState(10);

  const { data: shiftsData, isLoading } = useGetControlRoomOperatorShifts(
    controlRoomOperatorId,
    page,
    rows
  );

  const onPage = (event: any) => {
    setRows(event.rows);
    setPage(event.page + 1);
  };

  const getSeverity = (status: string): ShiftStatusColor => {
    switch (status) {
      case "OnGoing":
        return { bgColor: "#D2F6C1", textColor: "#369A03" };
      case "Completed":
        return { bgColor: "#E6E6E6", textColor: "#666666" };
      case "Cancelled":
        return { bgColor: "#FDD6C7", textColor: "#CC3201" };
      case "Scheduled":
        return { bgColor: "#C1E6F6", textColor: "#037CC4" };
      default:
        return { bgColor: "#D2F6C1", textColor: "#369A03" };
    }
  };

  const statusBodyTemplate = (rowData: IControlRoomOperatorShifts) => {
    const color = getSeverity(rowData.status);
    return (
      <Tag
        color={color.bgColor}
        className={`${color.bgColor} px-2.5 py-0.5 rounded`}
      >
        <p
          className={`${color.textColor} flex justify-center items-center mx-0 my-0`}
          style={{ color: `${color.textColor}` }}
        >
          {rowData.status}
        </p>
      </Tag>
    );
  };

  const dateTimeBodyTemplate = (
    rowData: IControlRoomOperatorShifts,
    field: "shiftStart" | "shiftEnd"
  ) => {
    if (!rowData[field]) {
      return "-";
    }

    // Parse the UTC time, add 2 hours, and format
    return moment(rowData[field]).add(2, "hours").format("YYYY-MM-DD HH:mm:ss");
  };

  return (
    <Spin tip="Fetching shifts" spinning={isLoading}>
      <DataTable
        value={shiftsData?.data || []}
        totalRecords={shiftsData?.totalCount || 0}
        paginator
        rows={rows}
        rowsPerPageOptions={[10, 25, 50, 100]}
        onPage={onPage}
        lazy
        dataKey="id"
        emptyMessage="No shifts found."
        rowHover
        scrollable
        scrollHeight="45rem"
        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} shifts"
        stateStorage="session"
        stateKey={`dt-state-control-room-operator-shifts-table-${controlRoomOperatorId}`}
        className="mx-3 bg-transparent"
      >
        <Column
          field="operatorName"
          header="Operator Name"
          headerStyle={TableHeader_2}
          bodyStyle={tableBody_2}
          className="bg-transparent"
        />
        <Column
          field="shiftStart"
          header="Shift Start"
          headerStyle={TableHeader_2}
          bodyStyle={tableBody_2}
          body={(rowData) => dateTimeBodyTemplate(rowData, "shiftStart")}
        />
        <Column
          field="shiftEnd"
          header="Shift End"
          headerStyle={TableHeader_2}
          bodyStyle={tableBody_2}
          body={(rowData) => dateTimeBodyTemplate(rowData, "shiftEnd")}
        />
        <Column
          field="status"
          header="Status"
          headerStyle={TableHeader_2}
          body={statusBodyTemplate}
        />
      </DataTable>
    </Spin>
  );
};

export default ControlRoomOperatorShiftsTable;
