/* eslint-disable max-len */
import React from "react";

export function OcticonDashboard({
  height, // number type by default
  width,
  viewBox = "0 0 16 16",
  svgColor = "#888888",
}: CustomSVGProp) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height={height}
      width={width}
      viewBox={viewBox || "0 0 16 16"}
    >
      <path
        fillRule="evenodd"
        d="M9 5H8V4h1v1zm4 3h-1v1h1V8zM6 5H5v1h1V5zM5 8H4v1h1V8zm11-5.5l-.5-.5L9 7c-.06-.02-1 0-1 0c-.55 0-1 .45-1 1v1c0 .55.45 1 1 1h1c.55 0 1-.45 1-1v-.92l6-5.58zm-1.59 4.09c.19.61.3 1.25.3 1.91c0 3.42-2.78 6.2-6.2 6.2c-3.42 0-6.21-2.78-6.21-6.2c0-3.42 2.78-6.2 6.2-6.2c1.2 0 2.31.34 3.27.94l.94-.94A7.459 7.459 0 0 0 8.51 1C4.36 1 1 4.36 1 8.5C1 12.64 4.36 16 8.5 16c4.14 0 7.5-3.36 7.5-7.5c0-1.03-.2-2.02-.59-2.91l-1 1z"
        fill={svgColor}
      />
    </svg>
  );
}
export default OcticonDashboard;
