/* eslint-disable max-len */
import React from "react";
import Colors from "../../config/Colors";

export function ArrowDownward({
  height, // number type by default
  width,
  viewBox = "0 0 24 24",
  svgColor = `${Colors.appMainColor}`,
}: CustomSVGProp) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height={height}
      width={width}
      viewBox={viewBox || "0 0 24 24"}
      fill="none"
    >
      <path
        fill={svgColor || "#888888"}
        d="m12 20l-8-8l1.4-1.425l5.6 5.6V4h2v12.175l5.6-5.6L20 12l-8 8Z"
      />
    </svg>
  );
}
export default ArrowDownward;
