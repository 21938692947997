import React, { useEffect } from "react";
import { useParams, useHistory } from "react-router-dom";

import HeaderComponent from "@/components/Header/Header";
import ControlRoomOperatorShiftsTable from "@/components/Table/ControlRoomOperatorShiftsTable";
import { Button } from "@/components/ui/button";

function ControlRoomOperatorShifts() {
  const { operatorId } = useParams<{ operatorId: string }>();
  const navigate = useHistory();

  useEffect(() => {
    if (!operatorId || !Number(operatorId)) {
      navigate.push("/home/control-room-operators");
    }
  }, [operatorId, navigate]);

  return (
    <div className="flex w-full h-full bg-backgroundColor">
      <div className="w-full h-full max-h-screen overflow-y-auto disable-scrollbars">
        <HeaderComponent title="Shifts" />
        <div className="flex flex-row items-center justify-between w-full gap-1 px-3 mb-2">
          <Button
            variant="outline"
            className="w-auto h-12 rounded-lg"
            onClick={() => navigate.push("/home/control-room-operators")}
          >
            Back to Operators
          </Button>
        </div>
        <ControlRoomOperatorShiftsTable
          controlRoomOperatorId={Number(operatorId)}
        />
      </div>
    </div>
  );
}

export default ControlRoomOperatorShifts;
